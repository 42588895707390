<template lang="pug">
    .content__main.-without-right-aside
        recommended-people
</template>
 
<script>
import RecommendedPeople from "@/components/recommended-people/recommended-people";
export default {
    name: "RecommendedPeoplePage",
    components: { RecommendedPeople },
    props: {
        photoType: {
            type: String,
            require: true,
            default: "anfas and profile",
        },
    },
    data: () => ({}),
    mounted() {
        this.$store.dispatch("setPage", "recommended-people");
    },
};
</script>
