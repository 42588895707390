<template lang="pug">
    .recommended-people
        ._empty(v-if="!recommendationsList.length") {{ locale("no-persons") }}
        ._content(v-else)
            ._tabs
                ._tab(:class="{active: tab === 'partner'}" @click="tab = 'partner'") {{ locale("tabs.partner") }}
                ._tab.disabled(:class="{active: tab === 'love'}" :data-help="locale('tabs.coming')")
                    svg-icon(icon-name="lock")._tab-locked-icon
                    | {{ locale("tabs.love") }}
            ._head
                portal-target(name="user-head")
                ._recomendation-type(v-if="person.category && pageWidth > 767")
                    svg-icon(:icon-name="getIcon(person.category)")._recomendation-type-icon
                    ._recomendation-type-name(v-html="typeNames[person.category.toUpperCase()]")
            ._content-body
                vue-slick-carousel(ref="slider" :infinite="false" :slidesToShow="1" @beforeChange="changeActiveIndex" @afterChange="changeActivePerson" v-bind="slickSettings")._slider
                    ._slide(v-for="(p) in recommendationsList" :key="person.id")
                        ._body
                            ._avatar-box
                                user-photo(:photo="p.person_avatar" :micro="false" :thumbs="false" custom-no-avatar="no-avatar-alternate")._avatar
                            ._user
                                portal(to="user-head" :disabled="pageWidth > 767")
                                    ._user-head(v-if="isDesktopView")
                                        ._user-type
                                            svg-icon._user-type-icon(:icon-name="getTypeIcon(p.person_type)" addedCatalog="leader-types")
                                        ._user-group-text-info
                                            ._user-name {{ p.person_name || locale("newbie", {}, true) }}
                                            ._user-leader-type {{ p.person_type || locale("type-undefined") }}
                                    ._user-head(v-if="isMobileView")
                                        ._user-type
                                            svg-icon._user-type-icon(:icon-name="getTypeIcon(person.person_type)" addedCatalog="leader-types")
                                        ._user-group-text-info
                                            ._user-name {{ person.person_name || locale("newbie", {}, true) }}
                                            ._user-leader-type {{ person.person_type || locale("type-undefined") }}
                                transition(name="fadeIn")
                                    portal(to=`user-ctrl-${personId}` :disabled="pageWidth > 767")
                                        ._user-ctrl(v-if="p.__typename === 'RecommendedPeople'")
                                            router-link(:to="p.person_id")._profile-link
                                                ui-button._user-btn(size="medium" :text="locale('toProfile')")
                                            ui-button._user-btn.-next(size="medium" type="bordered" :text="locale('next')" @click.native="slider.next()")
                                transition(name="fadeIn")
                                    ._user-info(v-if="p.__typename === 'RecommendedPeople'")
                                        ._user-info-item
                                            ._user-info-item-title Возраст
                                            ._user-info-item-value {{ getAge(p.date_of_birth) }}&nbsp;
                                                span(v-if="p.date_of_birth") {{ declination(getAge(p.date_of_birth), [locale("age_1"), locale("age_2"), locale("age_3")]) }}

                                        ._user-info-item
                                            ._user-info-item-title {{ locale("money-target") }}
                                            ._user-info-item-value {{ p.about_user_goal || locale("no-goal") }}

                                        ._user-info-item
                                            ._user-info-item-title {{ locale("status") }}
                                            ._user-info-item-value {{ p.info_occupation || locale("no-status")  }}

                                        ._user-info-item
                                            ._user-info-item-title {{ locale("city") }}
                                            ._user-info-item-value {{ getLocation(p) }}

                                        ._user-info-item
                                            ._user-info-item-title {{ locale("spheres") }}
                                            ._user-info-item-value {{ p.about_user_career_spheres || locale("no-spheres") }}
                                        portal-target(name=`user-ctrl-${personId}`)._user-ctrl-target
                ._next-person(@click="slider.next()" :class="{hidden: !nextPerson}")
                    ._next-person-title {{ locale("next-recommendation") }}
                    ._next-person-group
                        loading(v-if="isLoading")._next-person-loading
                        user-photo(v-if="renderNextPersonPhoto" :photo="nextPerson.person_avatar" :micro="false" :thumbs="false" @loaded="isLoading = false")._next-person-photo
                        ._next-person-photo(v-else)
                        ._next-person-name {{ nextPerson.person_name === "Newbie" ? locale("newbie", {}, true) : nextPerson.person_name }}
                            span._next-person-type(v-if="pageWidth <= 991") {{ nextPerson.person_type }}
</template>

<script>
import myRecommendedPeopleQuery from "@/graphql/queries/myRecommendedPeople.query.graphql";
import gql from "graphql-tag";
import UiButton from "@/components/ui/ui-button/ui-button";
import UserPhoto from "@/components/ui/user-photo/user-photo";
import declination from "@/utils/declination";
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: "RecommendedPeople",
    components: {UserPhoto, UiButton, VueSlickCarousel},
    data: function () {
        return {
            c: 0,
            userHeadPosition: 106,
            nextId: null,
            renderNextPersonPhoto: true,
            person: {
                category: "RELATIONS",
            },
            tab: "partner",
            isLoading: true,
            isLoadingList: 0,
            recommendationsList: [],
            activePersonId: 0,
            icons: {
                ALL: "merlin-analyze",
                PROFESSIONAL: "sub-cat-prof",
                RELATIONS: "sub-cat-relations",
                MOTIVATION: "sub-cat-motivation",
                SELFASSESSMENT: "sub-cat-self-esteem",
            },
            typeNames: {
                ALL: "Загрузка...",
                PROFESSIONAL: this.locale("category_1"),
                RELATIONS: this.locale("category_2"),
                MOTIVATION: this.locale("category_3"),
                SELFASSESSMENT: this.locale("category_4"),
            }
        };
    },
    computed: {
        personId: state => state.person?.id || 0,
        typeIcons: (state) => state.$store.getters["personTypeIcons"],
        slider: (state) => state.$refs.slider,
        nextPerson: (state) =>
            state.recommendationsList.find((p) => p.id === state.nextId) || false,
        slickSettings: state => ({
            arrows: false,
            dots: false,
            draggable: state.pageWidth <= 991,
            infinite: false,
            autoHeight: true,
            useTransform: true
        })
    },
    mounted() {

        this.getPerson();
    },
    beforeMount() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.addFixedHead();
                window.addEventListener("resize", this.addFixedHead);
            }, 400);
        });
    },
    destroyed() {
        window.removeEventListener("scroll", this.fixedHeadHandler);
        window.removeEventListener("resize", this.addFixedHead);
    },
    methods: {
        declination,
        addFixedHead() {
            if(this.isMobileView) {
                const content = document.querySelector(".recommended-people__head div");
                const contentData = content.getBoundingClientRect();
                const userHead = document.querySelector(".recommended-people__head");
                //this.userHeadPosition = userHead.getBoundingClientRect().top;

                userHead.style.height = contentData.height + "px";
                content.style.top = "0";
                window.addEventListener("scroll", this.fixedHeadHandler);
            }
        },
        fixedHeadHandler() {
            const content = document.querySelector(".recommended-people__head div");
            const scrollTop = Math.abs(document.body.getBoundingClientRect().y);
            console.log("handler");
            content.style.position = scrollTop >= this.userHeadPosition ? "fixed" : "";
        },
        getTypeIcon($type) {
            const type = $type ? $type : "_hidden_";

            return this.typeIcons[type];
        },
        getAge(bod) {
            return this.calcAge(bod, this.locale("noAge"));
        },
        getLocation: function (person) {
            const location = [];

            if (person.info_city) {
                location.push(person.info_city);
            }

            if (person.info_country) {
                location.push(person.info_country);
            }

            if (location.length) {
                return location.join(", ");
            }

            return this.locale("no-city");
        },
        async getPerson() {
            this.isLoading = true;
            this.renderNextPersonPhoto = false;
            this.$nextTick(() => {
                this.renderNextPersonPhoto = true;
            });
            if (this.nextId === -1) return;
            await this.$apollo
                .query({
                    query: gql`
                        ${myRecommendedPeopleQuery}
                    `,
                    variables: {
                        category: "ALL",
                        currentId: this.nextId,
                    },
                })
                .then((r) => {
                    const currenPerson = r.data.myRecommendedPeople.current;

                    let person = this.recommendationsList.find(
                        (p) => p.id === currenPerson.id,
                    );

                    if (person) {
                        this.recommendationsList[this.activePersonId] =
                            currenPerson;
                    } else {
                        this.recommendationsList.push(currenPerson);
                    }

                    this.person = currenPerson;

                    if (r.data.myRecommendedPeople.prev) {
                        this.recommendationsList.push({
                            ...r.data.myRecommendedPeople.prev,
                            category: "ALL",
                        });
                        this.nextId = r.data.myRecommendedPeople.prev.id;
                        return;
                    }

                    this.nextId = -1;
                });
        },
        changeActiveIndex(oldIndex, index) {
            const activePerson = this.recommendationsList[index];
            if (
                +index > this.activePersonId &&
                activePerson.person_id === undefined
            ) {
                this.activePersonId = index;
                this.getPerson();
            }
            this.activePersonId = index;
        },
        changeActivePerson(index) {
            this.person = this.recommendationsList[index];
            this.nextId = this.recommendationsList[index + 1]?.id || false;
        },
        getIcon(type) {
            return this.icons[type.toUpperCase()];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "recommended-people";
</style>
